import { useState, useEffect } from "react";
import Head from "next/head";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import Link from "next/link";

import {
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
} from "@mantine/core";

import { NextLink } from "@mantine/next";
import { useForm } from "@mantine/form";
import { ArrowBackUp } from "tabler-icons-react";

import useAuth from "../lib/auth";

const useStyles = createStyles((theme) => ({
  logo: {
    fontFamily: "'Kdam Thmor Pro', sans-serif;",
    fontSize: 28,
    fontWeight: "bold",
    textShadow: "1px 1px #FA5251",
    textAlign: "center",

    span: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },

  regLink: {
    fontWeight: "bold",
    color: "#FA5251",
  },

  title: {
    marginTop: 0,
  },

  backHomeBtn: {
    marginTop: 15,
  },
}));

const Login: NextPage = () => {
  const router = useRouter();

  const { classes } = useStyles();

  const [visible, setVisible] = useState(false);

  const { userLogin } = useAuth();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },

    validate: {
      email: (value) => email_validate(value),
      password: (value) =>
        value.length < 2 ? "A mező kitöltèse kötelező!" : null,
    },
  });

  function email_validate(value: string) {
    if (value.length < 2) {
      return "A mező kitöltèse kötelező!";
    }
    if (value.length > 2 && !/^\S+@\S+$/.test(value)) {
      return "Hibás email cím!";
    }

    return null;
  }

  const handleSubmit = async (values: typeof form.values) => {
    setVisible(true);

    const email = values.email;
    const password = values.password;
    const remember = values.remember;

    userLogin({ email, password, remember });

    setVisible(false);
  };

  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_PAGE_NAME} - Bejelentkezés</title>
      </Head>

      <Container
        size={420}
        my={40}
      >
        <div className={classes.logo}>
          <Link
            href="/"
            legacyBehavior
          >
            <span>{process.env.NEXT_PUBLIC_PAGE_NAME}</span>
          </Link>
        </div>

        <Text
          color="dimmed"
          size="sm"
          align="center"
          mt={5}
        >
          Nincs fiókod?{" "}
          <Anchor
            href="/registration"
            size="sm"
            className={classes.regLink}
          >
            Regisztráció
          </Anchor>
        </Text>

        <Paper
          withBorder
          shadow="md"
          p={30}
          mt={30}
          radius="md"
        >
          <div style={{ position: "relative" }}>
            <LoadingOverlay
              visible={visible}
              overlayBlur={2}
            />
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Text
                size="xl"
                align="center"
                component="h1"
                className={classes.title}
              >
                Bejelentkezés
              </Text>
              <TextInput
                label="E-mail"
                placeholder="E-mail cím"
                {...form.getInputProps("email")}
              />
              <PasswordInput
                label="Jelszó"
                placeholder="Jelszó"
                mt="md"
                {...form.getInputProps("password")}
              />
              <Group
                position="apart"
                mt="md"
              >
                <Checkbox
                  label="Belépve maradok"
                  {...form.getInputProps("remember", { type: "checkbox" })}
                />
                <Link
                  href="/forgotten-password"
                  legacyBehavior
                >
                  <Anchor size="sm">Elfelejtetted a jelszót?</Anchor>
                </Link>
              </Group>
              <Button
                fullWidth
                mt="xl"
                color="red"
                type="submit"
              >
                Belépés
              </Button>
            </form>
          </div>
        </Paper>

        <Link
          href="/"
          legacyBehavior
        >
          <Button
            variant="subtle"
            color="dark"
            size="xs"
            className={classes.backHomeBtn}
          >
            <ArrowBackUp
              size={16}
              strokeWidth={1}
            />
            <span style={{ marginLeft: 5 }}>Kezdőlap</span>
          </Button>
        </Link>
      </Container>
    </>
  );
};

export default Login;
